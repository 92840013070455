import { render, staticRenderFns } from "./OccasionBrandCards.vue?vue&type=template&id=e92149be&scoped=true"
import script from "./OccasionBrandCards.vue?vue&type=script&lang=js"
export * from "./OccasionBrandCards.vue?vue&type=script&lang=js"
import style0 from "./OccasionBrandCards.vue?vue&type=style&index=0&id=e92149be&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "e92149be",
  null
  
)

/* custom blocks */
import block0 from "@dundle/locale/data/christmas.json?vue&type=custom&index=0&blockType=i18n&issuerPath=%2Fbuilds%2Fkorsit-bv%2Fdundle%2Fservice%2Fdundle.com%2Fcomponents%2Foccasion%2FOccasionBrandCards.vue&external"
if (typeof block0 === 'function') block0(component)
import block1 from "@dundle/locale/data/blackfriday.json?vue&type=custom&index=1&blockType=i18n&issuerPath=%2Fbuilds%2Fkorsit-bv%2Fdundle%2Fservice%2Fdundle.com%2Fcomponents%2Foccasion%2FOccasionBrandCards.vue&external"
if (typeof block1 === 'function') block1(component)

export default component.exports