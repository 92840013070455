<template>
    <a
        :href="article.canonical_url"
        :target="newTab ? '_blank' : '_self'"
        class="article-wrapper"
        :data-article-id="`article-${article.id}`"
    >
        <div class="article-banner">
            <MagazineImage
                class="banner"
                :src="article.banner.url"
                :alt="article.title"
                :responsive-widths="[768, 480, 120]"
                fit="cover"
            />
        </div>

        <div v-if="article.category" class="caption">
            <span class="category small" :style="{ color: getCategoryColor }">{{
                article.category.name.toUpperCase()
            }}</span>
            <span class="date small">{{ ' • ' + formatDate(article.published) }}</span>
            <br />
            <span class="sub-title">{{ article.title }}</span>
        </div>
    </a>
</template>

<script>
import { MagazineImage } from '@dundle/ui/components';

export default {
    components: {
        MagazineImage,
    },

    props: {
        article: {
            type: Object,
            required: true,
        },
        displayCategoryName: {
            type: Boolean,
            default: true,
        },
        newTab: {
            type: Boolean,
            required: false,
            default: false,
        },
    },
    computed: {
        getCategoryColor() {
            return this.article.category.labelColor || '#616E71';
        },
    },
    methods: {
        formatDate(updatedAt) {
            const date = new Date(updatedAt);
            const formatLocalDate = this.article.locale + '-' + this.$locale.regio;
            const formatted = date
                .toLocaleDateString(formatLocalDate, {
                    year: 'numeric',
                    month: 'long',
                    day: 'numeric',
                })
                .replace(',', '');
            return formatted;
        },
    },
};
</script>

<style lang="scss" scoped>
.article-wrapper {
    text-decoration: none;
    display: flex;
    margin-bottom: 1rem;

    @media (min-width: 1024px) {
        display: block;
        margin-bottom: 0;
    }

    .article-banner {
        display: flex;
        align-content: center;
        justify-content: flex-end;
        width: 8rem;
        height: 9rem;
        margin-right: 1rem;
        @media (min-width: 1024px) {
            width: 100%;
            margin-bottom: 1rem;
        }

        img.banner {
            width: 8rem;
            height: 100%;
            max-width: auto;
            display: block;
            object-fit: cover;
            object-position: center;
            border-radius: $border-radius-default;
            @media (max-width: 425px) {
                max-width: 8rem;
            }
            @media (min-width: 1024px) {
                width: 100%;
            }
            @media (max-width: 768px) {
                max-width: 9rem;
            }
        }
    }

    .caption {
        // padding-top: 0.5rem;
        padding-bottom: 2rem;

        @media (min-width: 1024px) {
            padding-top: 0;
        }

        .date {
            color: $color-grey-4;
        }
        .category {
            font-weight: bold;
        }
        .sub-title {
            font-weight: bold;
            color: $color-text-bold;
            overflow-wrap: break-word;
        }

        .top-title {
            line-height: 1rem;
            margin-bottom: 0.3rem;
        }
    }
    &:hover {
        cursor: pointer;
        .sub-title {
            text-decoration: underline;
        }
    }
}
</style>
