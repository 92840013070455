<template>
    <div class="position-relative">
        <h2 class="mb-1 mt-0">
            {{ translate(`${occasion}.title.${titleKey}`) }}
        </h2>
        <p v-if="showDescription">{{ translate(`${occasion}.description.${titleKey}`) }}</p>
        <div class="position-relative">
            <div v-if="snapStep > 4" class="d-none d-md-block" @click="scrollToLeft">
                <UIIcon color="#007cd2" name="chevron-left" class="scroll-chevron left"></UIIcon>
            </div>
            <UIGridRow :id="titleKey" ref="productRow" class="category-row d-flex">
                <div
                    v-for="brand in brands"
                    :id="brand.slug"
                    :key="brand.name"
                    class="card"
                    @click="clickOccasionBrand(brands)"
                >
                    <HomepageCard :brand="brand" :colour="brand.color || '#ff394f'" variant="large"></HomepageCard>
                </div>
            </UIGridRow>
            <div v-if="snapStep < brands.length" @click="scrollToRight">
                <UIIcon
                    color="#007cd2"
                    name="chevron-right"
                    class="scroll-chevron right d-none d-md-inline-flex"
                ></UIIcon>
            </div>
        </div>
    </div>
</template>

<script>
import { UIIcon, UIGridRow } from '@dundle/ui/components';
import { HomepageCard } from '~/components/homepage';
import TranslationMixin from '~/mixins/TranslationMixin';

export default {
    name: 'OccasionBrandCards',
    components: {
        HomepageCard,
        UIIcon,
        UIGridRow,
    },
    mixins: [TranslationMixin],
    props: {
        occasion: {
            type: String,
            required: true,
        },
        brands: {
            type: Array,
            required: true,
        },
        titleKey: {
            type: String,
            required: true,
        },
        showDescription: {
            type: Boolean,
            required: false,
            default: false,
        },
    },
    data() {
        return {
            scrollLeft: 0,
            snapStep: 4,
        };
    },
    methods: {
        scrollToRight() {
            if (this.snapStep === this.brands.length) return;
            this.$refs.productRow.$el.scrollTo({ left: this.scrollLeft + 251.5, behavior: 'smooth' });
            this.snapStep++;
            this.scrollLeft += 251.5;
        },
        scrollToLeft() {
            if (this.snapStep <= 4) return;
            this.$refs.productRow.$el.scrollTo({ left: this.scrollLeft - 251.5, behavior: 'smooth' });
            this.snapStep--;
            this.scrollLeft -= 251.5;
        },
        clickOccasionBrand(brand) {
            this.$gtm?.push({ event: 'event_homepage_best_seller_clicked', value: brand.name });

            this.$analytics.viewItemList('occasion_product', 'Occasion Product', brand.products);
        },
    },
};
</script>

<style lang="scss" scoped>
.category-row {
    margin: auto;
    justify-content: space-between;
    overflow-x: scroll;
    flex-wrap: nowrap;

    scrollbar-width: none;
    &::-webkit-scrollbar {
        display: none; /* Safari and Chrome */
    }

    @media screen and (max-width: 996px) {
        width: calc(100% + 2rem);
        padding-right: 1rem;

        margin-left: -1rem;
        padding-left: 1rem;
    }

    .card {
        margin-right: 1rem;

        @media screen and (min-width: 768px) {
            margin-right: 0.6rem;
        }
    }
}

.scroll-chevron {
    position: absolute;

    top: 110px;
    font-size: 1.5rem;
    z-index: 1;

    &:hover {
        cursor: pointer;
        filter: invert(30%) sepia(78%) saturate(1051%) hue-rotate(175deg) brightness(94%) contrast(97%);
    }

    &.right {
        right: -28px;
    }

    &.left {
        left: -28px;
    }
}
</style>

<i18n src="@dundle/locale/data/christmas.json"></i18n>
<i18n src="@dundle/locale/data/blackfriday.json"></i18n>
